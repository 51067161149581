import { INavElement, NavRoot } from "xa-navigation";

export class Level0Routes extends NavRoot {
    public static levelName: string = "NONE";
    route1: INavElement = {
        route: "/",
        text: "navRoute1",
        sort_order: 1
    };
    route2: INavElement = {
        route: "/about",
        text: "navRoute2",
        sort_order: 2
    };
    route3: INavElement = {
        route: "/locations",
        text: "navRoute3",
        sort_order: 3
    };
    route4: INavElement = {
        route: "/projects",
        text: "navRoute4",
        sort_order: 4
    };
    route5: INavElement = {
        route: "/lease",
        text: "navRoute5",
        sort_order: 5
    };
    route6: INavElement = {
        route: "/news",
        text: "navRoute6",
        sort_order: 6
    };
    route7: INavElement = {
        route: "/contact-us",
        text: "navRoute7",
        sort_order: 7
    };
    route8: INavElement = {
        route: "/applications",
        text: "navRoute8",
        sort_order: 8
    };
}
