import React from "react";
import { IWithRouter, NavigationRouter } from "xa-navigation";
import { withRouter, Switch } from "react-router";
import { ApplicationAccess } from "access-factory";
import ElementDataView from "../ElementViewer/View/ElementData.view";
import LocationsView from "../Locations/View/Locations.view";
import PageNotFound from "../E404/View/E404.view";
import ProjectsView from "../Projects/View/Projects.view";
import ContactUs from "../ContactUs/View/ContactUs.view";
import MainPage from "../MainPage/View/MainPage.view";
import NewsPage from "../News/View/News.view";
import AboutUs from "../AboutUs/View/AboutUs.view";
import Layout from "../Layout/View/Layout.view";
import LeaseController from "../Lease/Controller/Lease.controller";
import ApplicationsView from "../Applications/View/Applications.view";
import SimplePDFPage from "../SimplePDFPage/View/SimplePDFPage.view";

const AppRoutes: React.FC<IWithRouter> = (props): React.ReactElement => {
    const routes: React.ReactNode = (
        <Switch>
            <NavigationRouter path="/contact-us" component={ContactUs} exact={true} />
            <NavigationRouter path="/news" component={NewsPage} exact={true} />
            <NavigationRouter path="/projects" component={ProjectsView} exact={true} />
            <NavigationRouter
                path="/projects/:id"
                component={ElementDataView}
                exact={true}
                dataSource={"project"}
            />
            <NavigationRouter path="/locations" component={LocationsView} exact={true} />
            <NavigationRouter
                path="/locations/:id"
                component={ElementDataView}
                exact={true}
                dataSource={"location"}
            />
            <NavigationRouter
                path="/lease/:id"
                component={ElementDataView}
                exact={true}
                dataSource={"lease"}
            />
            <NavigationRouter path="/about" component={AboutUs} exact={true} />
            <NavigationRouter path="/applications" component={ApplicationsView} exact={true} />
            <NavigationRouter
                path="/applications/:id"
                component={ElementDataView}
                exact={true}
                dataSource={"application"}
            />
            <NavigationRouter path="/lease" component={LeaseController} exact={true} />
            <NavigationRouter path="/" component={MainPage} exact={true} />
            <NavigationRouter path="/richtergedeon" component={SimplePDFPage} exact={true} />
            <NavigationRouter path="/hatarut" component={SimplePDFPage} exact={true} />
            <NavigationRouter path="*" component={PageNotFound} exact={true} />
        </Switch>
    );
    return (
        <ApplicationAccess prevent_autologin={true} Spinner={null}>
            <Layout>{routes}</Layout>
        </ApplicationAccess>
    );
};

export default withRouter(AppRoutes);
